import { pick } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { SEARCHABLE_PROFILE_FIELDS_LIST_KEY } from "@circle-react/components/shared/MemberFilters/ProfileFieldFilter/useProfileFieldsList";
import {
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { PROFILE_INFO_QUERY_KEY } from "@circle-react/hooks/profile/useProfileInfo";
import { useToast } from "@circle-react-uikit/ToastV2";
import {
  DESCRIPTION_FIELD_INPUTS,
  FIELD_INPUTS,
  FIELD_TYPE_SPECIFIC_INPUTS,
} from "../constants";
import { PAGE_PROFILE_FIELD_KEY } from "./usePageProfileFields";
import {
  getProfileFieldDetailQueryKey,
  processFieldType,
} from "./useProfileFieldDetail";

export const processBackendResponse = (response: any) => {
  if (!response) return;
  return response.map(processFieldType);
};

export const PROFILE_FIELD_LIST = "profile-fields";

export const useProfileFields = () => {
  const toastr = useToast();
  const queryClient = useQueryClient();

  const getActiveQueryOptions = useQuery(PROFILE_FIELD_LIST, async () => {
    const response = await reactQueryGet(internalApi.profileFields.index());
    return processBackendResponse(response);
  });

  const { mutateAsync: createField } = useMutation(
    payload => reactQueryPost(internalApi.profileFields.index(), payload),
    {
      onSuccess: async () => {
        await getActiveQueryOptions.refetch();
        await queryClient.invalidateQueries(PAGE_PROFILE_FIELD_KEY);
        await queryClient.invalidateQueries(SEARCHABLE_PROFILE_FIELDS_LIST_KEY);
        queryClient.removeQueries(PROFILE_INFO_QUERY_KEY);
      },
      onError: (err: any) => {
        toastr.error(err.errorDetails ?? err.message);
      },
    },
  );
  const { mutateAsync: updateField } = useMutation(
    ({ id, payload }: any) =>
      reactQueryPatch(internalApi.profileFields.detail(id), payload),
    {
      onSuccess: async data => {
        await getActiveQueryOptions.refetch();
        await queryClient.invalidateQueries(SEARCHABLE_PROFILE_FIELDS_LIST_KEY);
        queryClient.removeQueries(PAGE_PROFILE_FIELD_KEY);
        queryClient.removeQueries(PROFILE_INFO_QUERY_KEY);
        await queryClient.invalidateQueries(
          getProfileFieldDetailQueryKey(data.id),
        );
      },
      onError: (err: any) => {
        toastr.error(err.message);
      },
    },
  );

  const getFieldSpecificInputs = (data: any) => {
    const { [DESCRIPTION_FIELD_INPUTS.FIELD_TYPE]: selectedFieldType } = data;

    return Object.values(FIELD_TYPE_SPECIFIC_INPUTS[selectedFieldType]);
  };

  const getPayload: any = (data: any) => {
    const selectedFieldTypeParams = getFieldSpecificInputs(data);
    const paramNames: any[] = [
      ...FIELD_INPUTS,
      "pages_attributes",
      ...selectedFieldTypeParams,
    ];
    return pick(data, paramNames);
  };

  const onNewProfileField = (data: any, onClose: () => void) => {
    void createField(getPayload(data), {
      onSuccess: () => {
        toastr.success(t("profile_fields.field_modal.success_message"));
        onClose();
      },
    });
  };

  const onEditProfileField = (id: any, data: any, onClose: () => void) => {
    void updateField(
      { id, payload: getPayload(data) },
      {
        onSuccess: () => {
          toastr.success(t("changes_saved"));
          onClose();
        },
      },
    );
  };

  return {
    getOptions: getActiveQueryOptions,
    onNewProfileField,
    onEditProfileField,
  };
};
