import { useMutation, useQuery, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import type { ApiError } from "@circle-react/config/CustomErrors";
import { usePunditUserContext } from "@circle-react/contexts";
import { getIsCommunityMember } from "@circle-react/helpers/audienceHelpers";
import {
  reactQueryGet,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import type { AudienceType } from "@circle-react/types/AudienceList";
import type { CommunityMember } from "@circle-react/types/CommunityMember";
import type { Field } from "@circle-react-shared/ProfileFields/FormFields/CustomFieldsList";
import { useToast } from "@circle-react-uikit/ToastV2";

export const PROFILE_INFO_QUERY_KEY = "PROFILE_INFO";

interface InvitationStatusHistory {
  invited: string | null;
  invite_opened: string | null;
  account_created: string | null;
  profile_completed: string | null;
}

export interface ProfileResponse extends CommunityMember {
  locale?: string;
  is_deleted: boolean;
  profile_fields: {
    visible: Field[];
    not_visible: any[];
  };
  invitation_status: string | null;
  invitation_status_history: InvitationStatusHistory;
  subscribed_to_marketing_emails?: boolean;
  is_invitation_onboarding_flow: boolean;
}

export const useProfileInfo = ({
  publicId,
  params = {},
  type = "CommunityMember",
}: {
  publicId: string;
  params?: Record<string, unknown>;
  type?: AudienceType;
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { refetch: refetchPunditUserContext } = usePunditUserContext();
  const queryKey = [PROFILE_INFO_QUERY_KEY, publicId, params];

  const fetchMemberProfileInfo = () =>
    reactQueryGet(
      internalApi.profile.show({ memberPublicId: publicId, params }),
    );

  const fetchLeadProfileInfo = () =>
    reactQueryGet(
      internalApi.profile.leadShow({ leadPublicId: publicId, params }),
    );

  const getProfileInfoQuery = useQuery<ProfileResponse, ApiError>(
    queryKey,
    getIsCommunityMember({ type })
      ? fetchMemberProfileInfo
      : fetchLeadProfileInfo,
    {
      retry: false,
      enabled: Boolean(publicId),
    },
  );

  const updateProfileInfoMutation = useMutation<unknown, ApiError>(
    formData =>
      reactQueryPut(
        internalApi.profile.update({
          memberPublicId: publicId,
        }),
        formData,
      ),
    {
      onSuccess: async () => {
        const currentProfileInfo = getProfileInfoQuery?.data;
        const currentLocale = currentProfileInfo?.locale;

        toast.success(t("profile_saved_successfully"));
        await refetchPunditUserContext();
        await queryClient.invalidateQueries(PROFILE_INFO_QUERY_KEY);

        const newProfileInfo = await queryClient.fetchQuery(
          queryKey,
          fetchMemberProfileInfo,
        );
        const newLocale = newProfileInfo?.locale;

        window.webview?.onProfileInfoSaved?.();
        // reload the page if the locale has changed
        if (currentLocale !== newLocale) {
          window.location.reload();
        }
      },
      onError: err => {
        toast.error(err.message);
      },
    },
  );

  return {
    getProfileInfoQuery,
    updateProfileInfoMutation,
  };
};
