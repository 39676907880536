import { immer } from "zustand/middleware/immer";

export interface UISliceProps {
  ui: {
    liveChatRoomUuid: null | string;
    dmSelectedTabIndex: number;
    setLiveChatRoomUuid: (uuid: string) => void;
  };
}

export const createUISlice = immer<UISliceProps>(set => ({
  ui: {
    liveChatRoomUuid: null,
    setLiveChatRoomUuid: (uuid: string) =>
      set(state => {
        state.ui.liveChatRoomUuid = uuid;
      }),
    dmSelectedTabIndex: 0,
    setDmSelectedTabIndex: (index: number) =>
      set(state => {
        state.ui.dmSelectedTabIndex = index;
      }),
  },
}));
