import {
  reactQueryGet,
  reactQueryPost,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

const update = (planId, planCouponId, source) =>
  reactQueryPut(
    internalApi.AccountSubscriptions.update(planId, planCouponId, source),
  );

const renew = planId =>
  reactQueryPost(internalApi.AccountSubscriptions.renew(planId));

const canDowngrade = async planId =>
  await reactQueryGet(internalApi.AccountSubscriptions.canDowngrade(planId));

const createMarketingHubSubscription = () =>
  reactQueryPost(
    internalApi.AccountSubscriptions.createMarketingHubSubscription(),
  );

const reactivateMarketingHubSubscription = () =>
  reactQueryPost(
    internalApi.AccountSubscriptions.reactivateMarketingHubSubscription(),
  );

export const accountSubscriptionApi = {
  update,
  renew,
  canDowngrade,
  createMarketingHubSubscription,
  reactivateMarketingHubSubscription,
};
