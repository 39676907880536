/* eslint-disable no-restricted-syntax -- TODO: Translate this */

export const timeZones2 = [
  {
    label: "(GMT +02:00) Kyiv",
    value: "Kyiv",
  },
  {
    label: "(GMT +02:00) Pretoria",
    value: "Pretoria",
  },
  {
    label: "(GMT +02:00) Riga",
    value: "Riga",
  },
  {
    label: "(GMT +02:00) Sofia",
    value: "Sofia",
  },
  {
    label: "(GMT +02:00) Tallinn",
    value: "Tallinn",
  },
  {
    label: "(GMT +02:00) Vilnius",
    value: "Vilnius",
  },
  {
    label: "(GMT +03:00) Baghdad",
    value: "Baghdad",
  },
  {
    label: "(GMT +03:00) Istanbul",
    value: "Istanbul",
  },
  {
    label: "(GMT +03:00) Kuwait",
    value: "Kuwait",
  },
  {
    label: "(GMT +03:00) Minsk",
    value: "Minsk",
  },
  {
    label: "(GMT +03:00) Moscow",
    value: "Moscow",
  },
  {
    label: "(GMT +03:00) Nairobi",
    value: "Nairobi",
  },
  {
    label: "(GMT +03:00) Riyadh",
    value: "Riyadh",
  },
  {
    label: "(GMT +03:00) St. Petersburg",
    value: "St. Petersburg",
  },
  {
    label: "(GMT +03:00) Volgograd",
    value: "Volgograd",
  },
  {
    label: "(GMT +03:30) Tehran",
    value: "Tehran",
  },
  {
    label: "(GMT +04:00) Abu Dhabi",
    value: "Abu Dhabi",
  },
  {
    label: "(GMT +04:00) Baku",
    value: "Baku",
  },
  {
    label: "(GMT +04:00) Muscat",
    value: "Muscat",
  },
  {
    label: "(GMT +04:00) Samara",
    value: "Samara",
  },
  {
    label: "(GMT +04:00) Tbilisi",
    value: "Tbilisi",
  },
  {
    label: "(GMT +04:00) Yerevan",
    value: "Yerevan",
  },
  {
    label: "(GMT +04:30) Kabul",
    value: "Kabul",
  },
  {
    label: "(GMT +05:00) Ekaterinburg",
    value: "Ekaterinburg",
  },
  {
    label: "(GMT +05:00) Islamabad",
    value: "Islamabad",
  },
  {
    label: "(GMT +05:00) Karachi",
    value: "Karachi",
  },
  {
    label: "(GMT +05:00) Tashkent",
    value: "Tashkent",
  },
  {
    label: "(GMT +05:30) Chennai",
    value: "Chennai",
  },
  {
    label: "(GMT +05:30) Kolkata",
    value: "Kolkata",
  },
  {
    label: "(GMT +05:30) Mumbai",
    value: "Mumbai",
  },
  {
    label: "(GMT +05:30) New Delhi",
    value: "New Delhi",
  },
  {
    label: "(GMT +05:30) Sri Jayawardenepura",
    value: "Sri Jayawardenepura",
  },
  {
    label: "(GMT +05:45) Kathmandu",
    value: "Kathmandu",
  },
  {
    label: "(GMT +05:00) Almaty",
    value: "Almaty",
  },
  {
    label: "(GMT +05:00) Astana",
    value: "Astana",
  },
  {
    label: "(GMT +06:00) Dhaka",
    value: "Dhaka",
  },
  {
    label: "(GMT +06:00) Urumqi",
    value: "Urumqi",
  },
  {
    label: "(GMT +06:30) Rangoon",
    value: "Rangoon",
  },
  {
    label: "(GMT +07:00) Bangkok",
    value: "Bangkok",
  },
  {
    label: "(GMT +07:00) Hanoi",
    value: "Hanoi",
  },
  {
    label: "(GMT +07:00) Jakarta",
    value: "Jakarta",
  },
  {
    label: "(GMT +07:00) Krasnoyarsk",
    value: "Krasnoyarsk",
  },
  {
    label: "(GMT +07:00) Novosibirsk",
    value: "Novosibirsk",
  },
  {
    label: "(GMT +08:00) Beijing",
    value: "Beijing",
  },
  {
    label: "(GMT +08:00) Chongqing",
    value: "Chongqing",
  },
  {
    label: "(GMT +08:00) Hong Kong",
    value: "Hong Kong",
  },
  {
    label: "(GMT +08:00) Irkutsk",
    value: "Irkutsk",
  },
  {
    label: "(GMT +08:00) Kuala Lumpur",
    value: "Kuala Lumpur",
  },
  {
    label: "(GMT +08:00) Perth",
    value: "Perth",
  },
  {
    label: "(GMT +08:00) Singapore",
    value: "Singapore",
  },
  {
    label: "(GMT +08:00) Taipei",
    value: "Taipei",
  },
  {
    label: "(GMT +08:00) Ulaanbaatar",
    value: "Ulaanbaatar",
  },
  {
    label: "(GMT +09:00) Osaka",
    value: "Osaka",
  },
  {
    label: "(GMT +09:00) Sapporo",
    value: "Sapporo",
  },
  {
    label: "(GMT +09:00) Seoul",
    value: "Seoul",
  },
  {
    label: "(GMT +09:00) Tokyo",
    value: "Tokyo",
  },
  {
    label: "(GMT +09:00) Yakutsk",
    value: "Yakutsk",
  },
  {
    label: "(GMT +09:30) Adelaide",
    value: "Adelaide",
  },
  {
    label: "(GMT +09:30) Darwin",
    value: "Darwin",
  },
  {
    label: "(GMT +10:00) Brisbane",
    value: "Brisbane",
  },
  {
    label: "(GMT +10:00) Canberra",
    value: "Canberra",
  },
  {
    label: "(GMT +10:00) Guam",
    value: "Guam",
  },
  {
    label: "(GMT +10:00) Hobart",
    value: "Hobart",
  },
  {
    label: "(GMT +10:00) Melbourne",
    value: "Melbourne",
  },
  {
    label: "(GMT +10:00) Port Moresby",
    value: "Port Moresby",
  },
  {
    label: "(GMT +10:00) Sydney",
    value: "Sydney",
  },
  {
    label: "(GMT +10:00) Vladivostok",
    value: "Vladivostok",
  },
  {
    label: "(GMT +11:00) Magadan",
    value: "Magadan",
  },
  {
    label: "(GMT +11:00) New Caledonia",
    value: "New Caledonia",
  },
  {
    label: "(GMT +11:00) Solomon Is.",
    value: "Solomon Is.",
  },
  {
    label: "(GMT +11:00) Srednekolymsk",
    value: "Srednekolymsk",
  },
] as const;
