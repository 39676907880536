import {
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const adminMarkAsRead = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.communityInbox.adminMarkAsRead({ id }));

export const memberMarkAsRead = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.communityInbox.memberMarkAsRead({ id }));

export const pauseAi = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.communityInbox.pauseAi({ id }));

export const resumeAi = ({ id }: { id: number }) =>
  reactQueryPatch(internalApi.communityInbox.resumeAi({ id }));

export const unreadMemberChatsCount = () =>
  reactQueryGet(internalApi.communityInbox.unreadMemberChatsCount());

export const unreadAdminPausedAiChatsCount = () =>
  reactQueryGet(internalApi.communityInbox.unreadAdminPausedAiChatsCount());

export const previewChat = ({
  community_bot_profile,
}: {
  community_bot_profile: object;
}) =>
  reactQueryPost(internalApi.communityInbox.preview(), {
    community_bot_profile,
  });

export const fetchChat = ({ id }: { id: number }) =>
  reactQueryGet(internalApi.communityInbox.show({ id }));

export const communityInboxApi = {
  adminMarkAsRead,
  memberMarkAsRead,
  previewChat,
  fetchChat,
  pauseAi,
  resumeAi,
  unreadMemberChatsCount,
  unreadAdminPausedAiChatsCount,
};
