import type { ParsedQuery } from "query-string";
import { t } from "@/i18n-js/instance";

export const getBillingInterval = (plan: any) =>
  plan.interval === "year"
    ? t("pricing_plans.billed_annually")
    : t("pricing_plans.billed_monthly");

export const hasCurrentPlanAnAlternativePlan = (currentPlan: any) =>
  currentPlan?.alternative_plan ?? false;

export const planIntervals = Object.freeze({
  month: "month",
  year: "year",
  quarter: "quarter",
  biannual: "biannual",
});

export const isIgnoredFeature = (feature: string): boolean => {
  const ignoredFeatures: string[] = [
    planFeatures.live_stream_max_host_capacity,
    planFeatures.live_stream_max_spectator_capacity,
  ];
  return ignoredFeatures.includes(feature);
};

export const isValueUnlimited = (value: number): boolean => value >= 1000000;

export type PlanIntervals = "month" | "year" | "quarter" | "biannual";

export const planActions = {
  upgrade: "upgrade",
  current_plan: "current_plan",
  resume: "resume",
  redeem_offer: "redeem_offer",
  renew: "renew",
  change_plan: "change_plan",
  downgrade: "downgrade",
  contact_support: "contact_support",
};

export type PlanActions = (typeof planActions)[keyof typeof planActions];

const isPlanAction = (buttonType: string) => (buttonString: string) =>
  buttonString === buttonType;

export const isCurrent = isPlanAction(planActions.current_plan);
export const isUpgrading = isPlanAction(planActions.upgrade);
export const isRedeemingOffer = isPlanAction(planActions.redeem_offer);
export const isResuming = isPlanAction(planActions.resume);
export const isRenewing = isPlanAction(planActions.renew);
export const isChanging = isPlanAction(planActions.change_plan);
export const isDowngrading = isPlanAction(planActions.downgrade);
export const shouldContactSupport = isPlanAction(planActions.contact_support);

export const shouldRenderBillingAfterCheckout = (
  queryParams: ParsedQuery<string>,
) => {
  const { checkout_succeeded = false } = queryParams;
  return Boolean(checkout_succeeded);
};

export const planFeatures = Object.freeze({
  sso: "sso",
  max_admin_count: "max_admin_count",
  max_space_count: "max_space_count",
  custom_css: "custom_css",
  api_access: "api_access",
  automated_moderation: "automated_moderation",
  advanced_reporting: "advanced_reporting",
  custom_domain: "custom_domain",
  max_moderator_count: "max_moderator_count",
  internal_custom_html: "internal_custom_html",
  post_admin_change: "post_admin_change",
  max_member_count: "max_member_count",
  payments_fee_percentage: "payments_fee_percentage",
  allow_addons: "allow_addons",
  email_whitelist: "email_whitelist",
  live_stream_max_host_capacity: "live_stream_max_host_capacity",
  live_stream_max_spectator_capacity: "live_stream_max_spectator_capacity",
  live_stream_monthly_stream_hours: "live_stream_monthly_stream_hours",
  live_stream_recording_hours: "live_stream_recording_hours",
  chat_space_members_limit: "chat_space_members_limit",
  profile_fields_enabled: "profile_fields_enabled",
  max_bulk_import_member_limit_tier_1: "max_bulk_import_member_limit_tier_1",
  max_bulk_import_member_limit_tier_2: "max_bulk_import_member_limit_tier_2",
  max_bulk_import_member_limit_on_trial:
    "max_bulk_import_member_limit_on_trial",
  live_room_participants_capacity: "live_room_participants_capacity",
  courses: "courses",
  customize_community: "customize_community",
  paywall_conversion_tracking_code: "paywall_conversion_tracking_code",
  max_dynamic_workflows_count: "max_dynamic_workflows_count",
  max_bulk_action_workflows_count: "max_bulk_action_workflows_count",
  max_scheduled_workflows_count: "max_scheduled_workflows_count",
  zapier_token_automatic_creation: "zapier_token_automatic_creation",
  affiliates: "affiliates",
  analytics_filters: "analytics_filters",
});
