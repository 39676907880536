import { usePunditUserContext } from "@circle-react/contexts";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import { useCommunityBotProfileSearch } from "./useCommunityBotProfileSearch";

export const useShouldShowAgentsTab = () => {
  const { botProfiles } = useCommunityBotProfileSearch({ name: "" });
  const { currentCommunitySettings } = usePunditUserContext();
  const isBotMessagingEnabled = Boolean(
    currentCommunitySettings?.community_bot_enabled,
  );

  const { memberChats } = useCommunityInboxStore();
  return (
    isBotMessagingEnabled &&
    (botProfiles?.length > 0 || memberChats?.length > 0)
  );
};
