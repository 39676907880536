export const toLocaleString = (num: number) =>
  num.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });

export function findDecimalPlaces(num: number) {
  if (Math.floor(num) === num) return 0;
  return num.toString().split(".")[1].length || 0;
}

export const abbreviateNumber = (num: number): string => {
  const units = [
    { value: 1e9, suffix: "b" },
    { value: 1e6, suffix: "m" },
    { value: 1e3, suffix: "k" },
  ];
  for (const unit of units) {
    if (num >= unit.value) {
      return (num / unit.value).toFixed(1).replace(/\.0$/, "") + unit.suffix;
    }
  }
  return num.toString();
};
