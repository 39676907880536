import { create } from "zustand";
import { createChatRoomsSlice } from "./slices/createChatRoomsSlice";
import type { ChatRoomsStoreProps } from "./slices/createChatRoomsSlice";
import { createCommunityInboxSlice } from "./slices/createCommunityInboxSlice";
import type { CommunityInboxSliceProps } from "./slices/createCommunityInboxSlice";
import { createThreadsSlice } from "./slices/createThreadsSlice";
import type { ChatThreadsStore } from "./slices/createThreadsSlice";
import { createUISlice } from "./slices/createUISlice";
import type { UISliceProps } from "./slices/createUISlice";
import { createUnreadMessagesSlice } from "./slices/createUnreadMessagesSlice";
import type { UnreadMessagesSliceProps } from "./slices/createUnreadMessagesSlice";

export type ChatStoreProps = ChatRoomsStoreProps &
  ChatThreadsStore &
  UnreadMessagesSliceProps &
  UISliceProps &
  CommunityInboxSliceProps;

export const useChatStore = create<ChatStoreProps>()((...a) => ({
  ...createChatRoomsSlice(...a),
  ...createThreadsSlice(...a),
  ...createUnreadMessagesSlice(...a),
  ...createUISlice(...a),
  ...createCommunityInboxSlice(...a),
}));
