import { immer } from "zustand/middleware/immer";
import { ACTIONS } from "@/react/components/SettingsApp/CommunityInbox/hooks/useScroll";
import { communityInboxApi } from "@circle-react/api/communityInboxApi";
import type { Profile } from "@circle-react/types/CommunityBot/Profile";
import type { Chat } from "@circle-react/types/CommunityInbox/Chat";
import type { Message } from "@circle-react/types/CommunityInbox/Message";

export type ChatType = "member" | "admin";

export interface CommunityInboxSliceProps {
  inbox: {
    currentChatId: number | null;
    setCurrentChatId: (chatId: number) => void;
    memberChats: Chat[];
    adminChats: Chat[];
    previewChat: Chat | null;
    setChats: (chats: Chat[], type: ChatType) => void;
    setPreviewChat: (chat: Chat) => void;
    fetchChat: (chatId: number, type: ChatType) => Promise<void>;
    appendChat: (chat: Chat, type: ChatType) => void;
    updateChat: (chat: Chat, type: ChatType) => void;
    destroyChat: (chatId: number, type: ChatType) => void;
    adminMarkAsRead: (chatId: number) => void;
    memberMarkAsRead: (chatId: number) => void;
    messages: Message[];
    setMessages: (messages: Message[]) => void;
    appendMessage: (message: Message, type: ChatType) => void;
    updateMessage: (message: Message) => void;
    destroyMessage: (messageId: number) => void;
    resetMessages: () => void;
    scrollAction: string;
    setScrollAction: (action: string) => void;
    currentBotProfile: Profile | null;
    setCurrentBotProfile: (profile: Profile | null) => void;
    totalUnreadChatsCount: number;
    fetchTotalUnreadChatsCount: () => Promise<void>;
    setIsProcessing: (isProcessing: boolean, type: ChatType) => void;
    setPreviewChatProcessing: (isProcessing: boolean) => void;
    totalUnreadPausedAiChatsCount: number;
    fetchPausedAiUnreadChatsCount: () => Promise<void>;
  };
}

export const createCommunityInboxSlice = immer<CommunityInboxSliceProps>(
  (set, get) => ({
    inbox: {
      currentChatId: null,
      setCurrentChatId: (chatId: number) =>
        set(state => {
          state.inbox.currentChatId = chatId;
        }),
      memberChats: [],
      adminChats: [],
      previewChat: null,
      totalUnreadChatsCount: 0,
      totalUnreadPausedAiChatsCount: 0,
      setChats: (chats: Chat[], type: ChatType) => {
        const chatsKeyName = type === "member" ? "memberChats" : "adminChats";
        set(state => {
          state.inbox[chatsKeyName] = chats;
        });
      },
      setPreviewChat: (chat: Chat) => {
        set(state => {
          state.inbox.previewChat = chat;
        });
      },
      fetchChat: async (chatId: number, type: ChatType) => {
        const chat = await communityInboxApi.fetchChat({ id: chatId });
        const chatsKeyName = type === "member" ? "memberChats" : "adminChats";
        const existingChat = get().inbox[chatsKeyName].find(
          c => c.id === chat.id,
        );
        existingChat
          ? set(state => {
              state.inbox.updateChat(chat, type);
            })
          : set(state => {
              state.inbox.appendChat(chat, type);
            });
      },
      appendChat: (chat: Chat, type: ChatType) => {
        const chatsKeyName = type === "member" ? "memberChats" : "adminChats";
        if (get().inbox[chatsKeyName].some(c => c.id === chat.id)) {
          return;
        }
        set(state => {
          state.inbox[chatsKeyName] = [chat, ...state.inbox[chatsKeyName]];
        });
      },
      updateChat: (chat: Chat, type: ChatType) => {
        const chatsKeyName = type === "member" ? "memberChats" : "adminChats";
        const index = get().inbox[chatsKeyName].findIndex(
          c => c.id === chat.id,
        );
        if (index !== -1) {
          set(state => {
            state.inbox[chatsKeyName][index] = chat;
          });
        } else {
          get().inbox.appendChat(chat, type);
        }
      },
      destroyChat: (chatId: number, type: ChatType) => {
        const chatsKeyName = type === "member" ? "memberChats" : "adminChats";
        set(state => {
          state.inbox[chatsKeyName] = state.inbox[chatsKeyName].filter(
            c => c.id !== chatId,
          );
        });
      },
      adminMarkAsRead: (chatId: number) => {
        communityInboxApi
          .adminMarkAsRead({ id: chatId })
          .then(() => {
            set(state => {
              const index = state.inbox.adminChats.findIndex(
                c => c.id === chatId,
              );
              if (index !== -1) {
                state.inbox.adminChats[index].has_unread_messages_for_admin =
                  false;
                if (state.inbox.adminChats[index].ai_snoozed) {
                  state.inbox.totalUnreadPausedAiChatsCount =
                    state.inbox.totalUnreadPausedAiChatsCount - 1;
                }
              }
            });
          })
          .catch(err => {
            console.error(err);
          });
      },
      memberMarkAsRead: (chatId: number) => {
        communityInboxApi
          .memberMarkAsRead({ id: chatId })
          .then(() => {
            set(state => {
              const index = state.inbox.memberChats.findIndex(
                c => c.id === chatId,
              );
              if (index !== -1) {
                state.inbox.memberChats[index].has_unread_messages_for_member =
                  false;
                state.inbox.totalUnreadChatsCount =
                  state.inbox.totalUnreadChatsCount - 1;
              }
            });
          })
          .catch(err => {
            console.error(err);
          });
      },
      messages: [],
      setMessages: (messages: Message[]) =>
        set(state => {
          state.inbox.messages = messages;
        }),
      appendMessage: (message: Message, type: ChatType = "member") => {
        if (
          message.community_inbox_chat_id !== Number(get().inbox.currentChatId)
        ) {
          // Set unread flag
          set(state => {
            const chatsKeyName =
              type === "member" ? "memberChats" : "adminChats";
            const chatIndex = state.inbox[chatsKeyName].findIndex(
              c => c.id === message.community_inbox_chat_id,
            );
            if (chatIndex === -1) {
              return;
            }
            if (type === "member") {
              state.inbox.memberChats[
                chatIndex
              ].has_unread_messages_for_member = true;
              state.inbox.totalUnreadChatsCount =
                state.inbox.memberChats.filter(
                  chat => chat.has_unread_messages_for_member,
                ).length;
            } else {
              state.inbox.adminChats[chatIndex].has_unread_messages_for_admin =
                true;
              state.inbox.totalUnreadPausedAiChatsCount =
                state.inbox.adminChats.filter(
                  chat => chat.has_unread_messages_for_admin && chat.ai_snoozed,
                ).length;
            }
          });
          return;
        }
        if (get().inbox.messages.some(m => m.id === message.id)) {
          return;
        }
        set(state => {
          state.inbox.messages = [...state.inbox.messages, message];
        });
      },
      updateMessage: (message: Message) => {
        if (
          message.community_inbox_chat_id !== Number(get().inbox.currentChatId)
        ) {
          return;
        }
        set(state => {
          const index = state.inbox.messages.findIndex(
            m => m.id === message.id,
          );
          if (index !== -1) {
            state.inbox.messages[index] = message;
          }
        });
      },
      destroyMessage: (messageId: number) => {
        set(state => {
          state.inbox.messages = state.inbox.messages.filter(
            m => m.id !== messageId,
          );
        });
      },
      resetMessages: () =>
        set(state => {
          state.inbox.messages = [];
        }),
      scrollAction: ACTIONS.FIRST_LOAD,
      setScrollAction: (action: string) => {
        set(state => {
          state.inbox.scrollAction = action;
        });
      },
      currentBotProfile: null,
      setCurrentBotProfile: (profile: any) => {
        set(state => {
          state.inbox.currentBotProfile = profile;
        });
      },
      fetchTotalUnreadChatsCount: () =>
        communityInboxApi
          .unreadMemberChatsCount()
          .then(({ unread_chats_count }: { unread_chats_count: number }) => {
            set(state => {
              state.inbox.totalUnreadChatsCount = unread_chats_count;
            });
          })
          .catch((err: any) => {
            console.error(err);
          }),
      fetchPausedAiUnreadChatsCount: () =>
        communityInboxApi
          .unreadAdminPausedAiChatsCount()
          .then(({ unread_chats_count }: { unread_chats_count: number }) => {
            set(state => {
              state.inbox.totalUnreadPausedAiChatsCount = unread_chats_count;
            });
          })
          .catch((err: any) => {
            console.error(err);
          }),
      setIsProcessing: (isProcessing: boolean, type: ChatType) => {
        set(state => {
          const chatsKeyName = type === "member" ? "memberChats" : "adminChats";
          const currentChat = state.inbox[chatsKeyName].find(
            chat => chat.id === state.inbox.currentChatId,
          );
          if (currentChat) {
            currentChat.is_processing = isProcessing;
          }
        });
      },
      setPreviewChatProcessing: (isProcessing: boolean) => {
        set(state => {
          const currentChat = state.inbox.previewChat;
          if (currentChat) {
            currentChat.is_processing = isProcessing;
          }
        });
      },
    },
  }),
);
