export const supportedCurrencies = {
  USD: {
    code: "USD",
    flag: "🇺🇸",
    symbol: "$",
  },
  BRL: {
    code: "BRL",
    flag: "🇧🇷",
    symbol: "R$",
  },
} as const;

export const defaultCurrency = supportedCurrencies["USD"];
