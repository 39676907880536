/* eslint-disable no-restricted-syntax -- TODO: Translate this */

export const timeZones1 = [
  {
    label: "(GMT -12:00) International Date Line West",
    value: "International Date Line West",
  },
  {
    label: "(GMT -11:00) American Samoa",
    value: "American Samoa",
  },
  {
    label: "(GMT -11:00) Midway Island",
    value: "Midway Island",
  },
  {
    label: "(GMT -10:00) Hawaii",
    value: "Hawaii",
  },
  {
    label: "(GMT -09:00) Alaska",
    value: "Alaska",
  },
  {
    label: "(GMT -08:00) Pacific Time (US \u0026 Canada)",
    value: "Pacific Time (US \u0026 Canada)",
  },
  {
    label: "(GMT -08:00) Tijuana",
    value: "Tijuana",
  },
  {
    label: "(GMT -07:00) Arizona",
    value: "Arizona",
  },
  {
    label: "(GMT -07:00) Chihuahua",
    value: "Chihuahua",
  },
  {
    label: "(GMT -07:00) Mazatlan",
    value: "Mazatlan",
  },
  {
    label: "(GMT -07:00) Mountain Time (US \u0026 Canada)",
    value: "Mountain Time (US \u0026 Canada)",
  },
  {
    label: "(GMT -06:00) Central America",
    value: "Central America",
  },
  {
    label: "(GMT -06:00) Central Time (US \u0026 Canada)",
    value: "Central Time (US \u0026 Canada)",
  },
  {
    label: "(GMT -06:00) Guadalajara",
    value: "Guadalajara",
  },
  {
    label: "(GMT -06:00) Mexico City",
    value: "Mexico City",
  },
  {
    label: "(GMT -06:00) Monterrey",
    value: "Monterrey",
  },
  {
    label: "(GMT -06:00) Saskatchewan",
    value: "Saskatchewan",
  },
  {
    label: "(GMT -05:00) Bogota",
    value: "Bogota",
  },
  {
    label: "(GMT -05:00) Eastern Time (US \u0026 Canada)",
    value: "Eastern Time (US \u0026 Canada)",
  },
  {
    label: "(GMT -05:00) Indiana (East)",
    value: "Indiana (East)",
  },
  {
    label: "(GMT -05:00) Lima",
    value: "Lima",
  },
  {
    label: "(GMT -05:00) Quito",
    value: "Quito",
  },
  {
    label: "(GMT -04:00) Atlantic Time (Canada)",
    value: "Atlantic Time (Canada)",
  },
  {
    label: "(GMT -04:00) Caracas",
    value: "Caracas",
  },
  {
    label: "(GMT -04:00) Georgetown",
    value: "Georgetown",
  },
  {
    label: "(GMT -04:00) La Paz",
    value: "La Paz",
  },
  {
    label: "(GMT -04:00) Puerto Rico",
    value: "Puerto Rico",
  },
  {
    label: "(GMT -04:00) Santiago",
    value: "Santiago",
  },
  {
    label: "(GMT -03:30) Newfoundland",
    value: "Newfoundland",
  },
  {
    label: "(GMT -03:00) Brasilia",
    value: "Brasilia",
  },
  {
    label: "(GMT -03:00) Buenos Aires",
    value: "Buenos Aires",
  },
  {
    label: "(GMT -03:00) Greenland",
    value: "Greenland",
  },
  {
    label: "(GMT -03:00) Montevideo",
    value: "Montevideo",
  },
  {
    label: "(GMT -02:00) Mid-Atlantic",
    value: "Mid-Atlantic",
  },
  {
    label: "(GMT -01:00) Azores",
    value: "Azores",
  },
  {
    label: "(GMT -01:00) Cape Verde Is.",
    value: "Cape Verde Is.",
  },
  {
    label: "(GMT +00:00) Edinburgh",
    value: "Edinburgh",
  },
  {
    label: "(GMT +00:00) Lisbon",
    value: "Lisbon",
  },
  {
    label: "(GMT +00:00) London",
    value: "London",
  },
  {
    label: "(GMT +00:00) Monrovia",
    value: "Monrovia",
  },
  {
    label: "(GMT +00:00) UTC",
    value: "UTC",
  },
  {
    label: "(GMT +01:00) Amsterdam",
    value: "Amsterdam",
  },
  {
    label: "(GMT +01:00) Belgrade",
    value: "Belgrade",
  },
  {
    label: "(GMT +01:00) Berlin",
    value: "Berlin",
  },
  {
    label: "(GMT +01:00) Bern",
    value: "Bern",
  },
  {
    label: "(GMT +01:00) Bratislava",
    value: "Bratislava",
  },
  {
    label: "(GMT +01:00) Brussels",
    value: "Brussels",
  },
  {
    label: "(GMT +01:00) Budapest",
    value: "Budapest",
  },
  {
    label: "(GMT +01:00) Casablanca",
    value: "Casablanca",
  },
  {
    label: "(GMT +01:00) Copenhagen",
    value: "Copenhagen",
  },
  {
    label: "(GMT +01:00) Dublin",
    value: "Dublin",
  },
  {
    label: "(GMT +01:00) Ljubljana",
    value: "Ljubljana",
  },
  {
    label: "(GMT +01:00) Madrid",
    value: "Madrid",
  },
  {
    label: "(GMT +01:00) Paris",
    value: "Paris",
  },
  {
    label: "(GMT +01:00) Prague",
    value: "Prague",
  },
  {
    label: "(GMT +01:00) Rome",
    value: "Rome",
  },
  {
    label: "(GMT +01:00) Sarajevo",
    value: "Sarajevo",
  },
  {
    label: "(GMT +01:00) Skopje",
    value: "Skopje",
  },
  {
    label: "(GMT +01:00) Stockholm",
    value: "Stockholm",
  },
  {
    label: "(GMT +01:00) Vienna",
    value: "Vienna",
  },
  {
    label: "(GMT +01:00) Warsaw",
    value: "Warsaw",
  },
  {
    label: "(GMT +01:00) West Central Africa",
    value: "West Central Africa",
  },
  {
    label: "(GMT +01:00) Zagreb",
    value: "Zagreb",
  },
  {
    label: "(GMT +01:00) Zurich",
    value: "Zurich",
  },
  {
    label: "(GMT +02:00) Athens",
    value: "Athens",
  },
  {
    label: "(GMT +02:00) Bucharest",
    value: "Bucharest",
  },
  {
    label: "(GMT +02:00) Cairo",
    value: "Cairo",
  },
  {
    label: "(GMT +02:00) Harare",
    value: "Harare",
  },
  {
    label: "(GMT +02:00) Helsinki",
    value: "Helsinki",
  },
  {
    label: "(GMT +02:00) Jerusalem",
    value: "Jerusalem",
  },
  {
    label: "(GMT +02:00) Kaliningrad",
    value: "Kaliningrad",
  },
] as const;
