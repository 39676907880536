import type { ReactNode } from "react";
import { createContext, useContext } from "react";

export interface ChatRoomContextValue {
  chatRoomUuid: string;
  isMessaging?: boolean;
}

const ChatRoomContext = createContext<ChatRoomContextValue>({
  chatRoomUuid: "main",
});

ChatRoomContext.displayName = "ChatRoomContext";

export interface ChatRoomContextProviderProps {
  chatRoomUuid: string;
  isMessaging?: boolean;
  children: ReactNode;
}

export const ChatRoomContextProvider = ({
  chatRoomUuid,
  isMessaging,
  children,
}: ChatRoomContextProviderProps) => (
  <ChatRoomContext.Provider value={{ chatRoomUuid, isMessaging }}>
    {children}
  </ChatRoomContext.Provider>
);

export const useChatRoomContext = () => useContext(ChatRoomContext);
