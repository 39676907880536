import { useCallback, useEffect, useRef, useState } from "react";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import type { Message } from "@circle-react/types/CommunityInbox/Message";

export const ACTIONS = {
  FIRST_LOAD: "FIRST_LOAD",
  PAGINATION: "PAGINATION",
  NEW_MESSAGE: "NEW_MESSAGE",
};

export const MESSAGE_CONTAINER_ID = "scrollableMessagesDiv";
const FIRST_LOAD_SCROLL_TIMEOUT = 500;

export const useScroll = () => {
  const [firstMessage, setFirstMessage] = useState<null | Message>(null);
  const { scrollAction, setScrollAction } = useCommunityInboxStore();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const adjustScroller = useCallback(() => {
    switch (scrollAction) {
      case ACTIONS.PAGINATION: {
        if (firstMessage) {
          const elementId = firstMessage?.id?.toString();
          const element = document.getElementById(elementId);
          element?.scrollIntoView();
        }
        break;
      }
      case ACTIONS.FIRST_LOAD: {
        const element = document.getElementById(MESSAGE_CONTAINER_ID);
        if (!element) return;
        timeoutRef.current = setTimeout(() => {
          element.scrollTop = element.scrollHeight;
        }, FIRST_LOAD_SCROLL_TIMEOUT);
        break;
      }
      case ACTIONS.NEW_MESSAGE: {
        const element = document.getElementById(MESSAGE_CONTAINER_ID);
        if (!element) return;
        element.scrollTop = element.scrollHeight;
        break;
      }
    }
  }, [firstMessage, scrollAction]);

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  return {
    setFirstMessage,
    setScrollAction,
    adjustScroller,
  };
};
