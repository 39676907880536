import { useEffect, useRef } from "react";

/** @deprecated Focusing on load can be an accessibility issue */
export const useFocusOnLoad = <Element extends HTMLElement>(
  dependencies: any[] = [],
) => {
  const ref = useRef<Element>(null);

  useEffect(() => {
    ref.current && ref.current?.focus();
  }, [ref, ref.current, ...dependencies]);

  return ref;
};
