import { americas } from "./americas";
import { europe } from "./europe";
import { others } from "./others";

export * from "./TaxIdType";

// Built from https://stripe.com/docs/billing/customer/tax-ids#supported-tax-id
// TODO: fetch this from Stripe API once they support it.
export const taxIdTypes = [...americas, ...europe, ...others].sort((a, b) =>
  a.label.localeCompare(b.label, "en", { sensitivity: "base" }),
);

export const isValidTaxIdValue = (taxIdEnum: string, taxIdValue: string) => {
  const taxIdType = taxIdTypes.find(t => t.enum === taxIdEnum);
  if (!taxIdType) {
    return false;
  }
  if (taxIdType.formOptions?.pattern) {
    return taxIdType.formOptions.pattern.test(taxIdValue);
  }
  return true;
};
