import { useEffect } from "react";
import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useCurrentPlanApi = (forceFromSubscription = false) => {
  const { currentCommunityMember } = usePunditUserContext();
  const isAdmin = currentCommunityMember?.is_admin;
  const {
    data: currentPlan,
    isLoading: isCurrentPlanLoading,
    error: errorFecthingCurrentPlan,
    refetch: refetchCurrentPlan,
    isRefetching: isRefetchingCurrentPlan,
  } = useQuery(
    ["community-current-plan"],
    () =>
      reactQueryGet(
        internalApi.communities.plans.show({
          forceFromSubscription,
        }),
      ),
    {
      retry: 2,
      enabled: false,
    },
  );

  useEffect(() => {
    if (isAdmin) {
      refetchCurrentPlan().catch(error => {
        console.error("Error fetching current plan" + error.message);
      });
    }
  }, [isAdmin]);

  return {
    currentPlan,
    isCurrentPlanLoading,
    errorFecthingCurrentPlan,
    refetchCurrentPlan,
    isRefetchingCurrentPlan,
  };
};
