import { usePunditUserContext } from "@circle-react/contexts";
import { useLgScreenMediaQuery, useMdScreenMediaQuery } from "./useMediaQuery";

export const useMobileWebFlags = (): {
  isMobileWebNewSpaceCustomizationUIEnabled: boolean;
} => {
  const { currentCommunitySettings } = usePunditUserContext();
  const isMdScreen = useMdScreenMediaQuery();
  const isLgScreen = useLgScreenMediaQuery();

  return {
    isMobileWebNewSpaceCustomizationUIEnabled:
      !!currentCommunitySettings?.mobile_web_new_space_customization_ui_enabled &&
      !isMdScreen &&
      !isLgScreen,
  };
};
