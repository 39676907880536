import { useCallback, useState } from "react";
import type { Location } from "history";
import { useHistory, useLocation } from "react-router-dom";
import { useLinkToRoot } from "@circle-react/hooks/useLinkToRoot";

interface LocationState {
  from?: {
    pathname?: string;
    path?: string;
    search?: string;
  };
}

export const useRedirectBack = (defaultRedirectPath?: string) => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { goToCommunityRootPath } = useLinkToRoot();

  const [currentLocation] = useState<Location<LocationState>>(location);
  const { from: prevLocation } = currentLocation?.state ?? {};

  const redirectBack = useCallback(
    (post_login_redirect = null) => {
      if (prevLocation?.pathname) {
        const { pathname, search } = prevLocation;
        history.push({ pathname, search });
      } else if (prevLocation?.path) {
        const { path, search } = prevLocation;
        history.push({ pathname: path, search });
      } else if (defaultRedirectPath) {
        history.push(defaultRedirectPath);
      } else {
        goToCommunityRootPath(post_login_redirect);
      }
    },
    [prevLocation, history, goToCommunityRootPath, defaultRedirectPath],
  );

  return {
    redirectBack,
  };
};
