import type { ReactNode } from "react";
import NiceModal, { useModal as useNiceModal } from "@ebay/nice-modal-react";

export const ModalManager = NiceModal;

export const useModal = useNiceModal;

interface DrawerProviderProps {
  children: ReactNode;
}

export const ModalManagerProvider = ({ children }: DrawerProviderProps) => (
  <ModalManager.Provider>{children}</ModalManager.Provider>
);
