import Cookies from "js-cookie";
import { useCurrentPlanApi } from "@/react/components/SettingsApp/Plan/hooks/useCurrentPlanApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { getCurrentCircleLocale } from "@circle-react/helpers/localeHelpers";
import { planTiers } from "@circle-react/helpers/planHelpers";

const isEnterpriseOfferCookieSet = () => {
  try {
    const enterpriseOfferCookie = Cookies.get("enterprise_offer_enabled");
    return enterpriseOfferCookie !== undefined;
  } catch (error) {
    return false;
  }
};

export const useEnterpriseOfferEnabled = () => {
  const { currentCommunitySettings } = usePunditUserContext();
  const currentLocale = getCurrentCircleLocale();
  const { currentPlan } = useCurrentPlanApi();

  const isEnterpriseOfferBetaEnabled =
    currentCommunitySettings?.enterprise_offer_enabled === true;

  const isCurrentPlanEnterprise = currentPlan?.tier === planTiers.enterprise;

  const isEnterpriseOfferEnabled =
    (isEnterpriseOfferCookieSet() || isEnterpriseOfferBetaEnabled) &&
    !isCurrentPlanEnterprise;

  const enterprisePriceIncreaseDate =
    currentLocale === "pt" ? "02/05" : "05/02";
  const savingsAmount = currentLocale === "pt" ? "R$2.200" : "$1,200";

  return {
    isEnterpriseOfferEnabled,
    enterprisePriceIncreaseDate,
    savingsAmount,
  };
};
