export const subscriptionStatuses = Object.freeze({
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
  active: "active",
  past_due: "past_due",
});

export const isSubscriptionIncompleteExpired = status =>
  status === subscriptionStatuses.incomplete_expired;

export const isSubscriptionIncomplete = status =>
  status === subscriptionStatuses.incomplete;
