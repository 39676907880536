/* eslint-disable no-restricted-syntax -- TODO: Translate this */

export const timeZones3 = [
  {
    label: "(GMT +12:00) Auckland",
    value: "Auckland",
  },
  {
    label: "(GMT +12:00) Fiji",
    value: "Fiji",
  },
  {
    label: "(GMT +12:00) Kamchatka",
    value: "Kamchatka",
  },
  {
    label: "(GMT +12:00) Marshall Is.",
    value: "Marshall Is.",
  },
  {
    label: "(GMT +12:00) Wellington",
    value: "Wellington",
  },
  {
    label: "(GMT +12:45) Chatham Is.",
    value: "Chatham Is.",
  },
  {
    label: "(GMT +13:00) Nuku'alofa",
    value: "Nuku'alofa",
  },
  {
    label: "(GMT +13:00) Samoa",
    value: "Samoa",
  },
  {
    label: "(GMT +13:00) Tokelau Is.",
    value: "Tokelau Is.",
  },
] as const;
