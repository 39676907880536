import { t } from "@/i18n-js/instance";
import type { TaxIdType } from "./TaxIdType";

export const americas: TaxIdType[] = [
  {
    countryCode: "BR",
    country: t("tax_ids.brazil"),
    label: t("tax_ids.brazil_cnpj"),
    example: "01.234.456/5432-10",
    description: t("tax_ids.brazil_cnpj_description"),
    enum: "br_cnpj",
    formOptions: {
      pattern: /(\d{14}|\d{2}.\d{3}.\d{3}\/\d{4}-\d{2})$/, // Just validate the format not the algorithm
    },
  },
  {
    countryCode: "BR",
    country: t("tax_ids.brazil"),
    label: t("tax_ids.brazil_cpf"),
    example: "123.456.789-87",
    description: t("tax_ids.brazil_cpf_description"),
    enum: "br_cpf",
    formOptions: {
      pattern: /(\d{11}|\d{3}.\d{3}.\d{3}-\d{2})$/, // Just validate the format not the algorithm
    },
  },
  {
    countryCode: "CA",
    country: t("tax_ids.canada"),
    label: t("tax_ids.canada_bn"),
    example: "123456789",
    description: t("tax_ids.canada_bn_description"),
    enum: "ca_bn",
  },
  {
    countryCode: "CA",
    country: t("tax_ids.canada"),
    label: t("tax_ids.canada_gst_hst"),
    example: "123456789RT0002",
    description: t("tax_ids.canada_gst_hst_description"),
    enum: "ca_gst_hst",
  },
  {
    countryCode: "CA",
    country: t("tax_ids.canada"),
    label: t("tax_ids.canada_pst_bc"),
    example: "PST-1234-5678",
    description: t("tax_ids.canada_pst_bc_description"),
    enum: "ca_pst_bc",
  },
  {
    countryCode: "CL",
    country: t("tax_ids.chile"),
    label: t("tax_ids.chile_rut"),
    example: "12.345.678-9",
    description: t("tax_ids.chile_rut_description"),
    enum: "cl_rut",
  },
  {
    countryCode: "CO",
    country: t("tax_ids.colombia"),
    label: t("tax_ids.colombia_nit"),
    example: "123.456.789-0",
    description: t("tax_ids.colombia_nit_description"),
    enum: "co_nit",
  },
  {
    countryCode: "MX",
    country: t("tax_ids.mexico"),
    label: t("tax_ids.mexico_rfc"),
    example: "ABCD010101ABC",
    description: t("tax_ids.mexico_rfc_description"),
    enum: "mx_rfc",
  },
  {
    countryCode: "PE",
    country: t("tax_ids.peru"),
    label: t("tax_ids.peru_ruc"),
    example: "12345678901",
    description: t("tax_ids.peru_ruc_description"),
    enum: "pe_ruc",
  },
  {
    countryCode: "US",
    country: t("tax_ids.united_states"),
    label: t("tax_ids.united_states_ein"),
    example: "12-3456789",
    description: t("tax_ids.united_states_ein_description"),
    enum: "us_ein",
  },
];
