import { t } from "@/i18n-js/instance";
import type { TaxIdType } from "./TaxIdType";

const commonDescription = t("tax_ids.europe_vat_description");

export const europe: TaxIdType[] = [
  {
    countryCode: "AT",
    country: t("tax_ids.austria"),
    label: t("tax_ids.austria_vat"),
    example: "ATU12345678",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "BE",
    country: t("tax_ids.belgium"),
    label: t("tax_ids.belgium_vat"),
    example: "BE0123456789",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "BG",
    country: t("tax_ids.bulgaria"),
    label: t("tax_ids.bulgaria_vat"),
    example: "BG0123456789",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "HR",
    country: t("tax_ids.croatia"),
    label: t("tax_ids.croatia_vat"),
    example: "HR12345678912",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "CY",
    country: t("tax_ids.cyprus"),
    label: t("tax_ids.cyprus_vat"),
    example: "CY12345678Z",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "CZ",
    country: t("tax_ids.czech_republic"),
    label: t("tax_ids.czech_republic_vat"),
    example: "CZ1234567890",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "DK",
    country: t("tax_ids.denmark"),
    label: t("tax_ids.denmark_vat"),
    example: "DK12345678",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "EE",
    country: t("tax_ids.estonia"),
    label: t("tax_ids.estonia_vat"),
    example: "EE123456789",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "FI",
    country: t("tax_ids.finland"),
    label: t("tax_ids.finland_vat"),
    example: "FI12345678",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "FR",
    country: t("tax_ids.france"),
    label: t("tax_ids.france_vat"),
    example: "FRAB123456789",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "DE",
    country: t("tax_ids.germany"),
    label: t("tax_ids.germany_vat"),
    example: "DE123456789",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "GB",
    country: t("tax_ids.united_kingdom"),
    label: t("tax_ids.united_kingdom_vat"),
    example: "GB123456789",
    description: t("tax_ids.united_kingdom_vat_description"),
    enum: "gb_vat",
  },
  {
    countryCode: "GR",
    country: t("tax_ids.greece"),
    label: t("tax_ids.greece_vat"),
    example: "EL123456789",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "HU",
    country: t("tax_ids.hungary"),
    label: t("tax_ids.hungary_vat"),
    example: "HU12345678912",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "IE",
    country: t("tax_ids.ireland"),
    label: t("tax_ids.ireland_vat"),
    example: "IE1234567AB",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "IT",
    country: t("tax_ids.italy"),
    label: t("tax_ids.italy_vat"),
    example: "IT12345678912",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "LV",
    country: t("tax_ids.latvia"),
    label: t("tax_ids.latvia_vat"),
    example: "LV12345678912",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "LT",
    country: t("tax_ids.lithuania"),
    label: t("tax_ids.lithuania_vat"),
    example: "LT123456789123",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "LU",
    country: t("tax_ids.luxembourg"),
    label: t("tax_ids.luxembourg_vat"),
    example: "LU12345678",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "MT",
    country: t("tax_ids.malta"),
    label: t("tax_ids.malta_vat"),
    example: "MT12345678",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "NL",
    country: t("tax_ids.netherlands"),
    label: t("tax_ids.netherlands_vat"),
    example: "NL123456789B12",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "PL",
    country: t("tax_ids.poland"),
    label: t("tax_ids.poland_vat"),
    example: "PL1234567890",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "PT",
    country: t("tax_ids.portugal"),
    label: t("tax_ids.portugal_vat"),
    example: "PT123456789",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "RO",
    country: t("tax_ids.romania"),
    label: t("tax_ids.romania_vat"),
    example: "RO1234567891",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "SK",
    country: t("tax_ids.slovakia"),
    label: t("tax_ids.slovakia_vat"),
    example: "SK1234567891",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "SI",
    country: t("tax_ids.slovenia"),
    label: t("tax_ids.slovenia_vat"),
    example: "SI12345678",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "ES",
    country: t("tax_ids.spain"),
    label: t("tax_ids.spain_vat"),
    example: "ESA1234567Z",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "ES",
    country: t("tax_ids.spain"),
    label: t("tax_ids.spain_cif"),
    example: "A12345678",
    description: t("tax_ids.spain_cif_description"),
    enum: "es_cif",
  },
  {
    countryCode: "SE",
    country: t("tax_ids.sweden"),
    label: t("tax_ids.sweden_vat"),
    example: "SE123456789123",
    description: commonDescription,
    enum: "eu_vat",
  },
  {
    countryCode: "CH",
    country: t("tax_ids.switzerland"),
    label: t("tax_ids.switzerland_vat"),
    example: "CHE-123.456.789 MWST",
    description: t("tax_ids.switzerland_vat_description"),
    enum: "ch_vat",
  },
  {
    countryCode: "IE",
    country: t("tax_ids.ireland"),
    label: t("tax_ids.northern_ireland_vat"),
    example: "XI123456789",
    description: t("tax_ids.northern_ireland_vat_description"),
    enum: "eu_vat",
  },
  {
    countryCode: "NO",
    country: t("tax_ids.norway"),
    label: t("tax_ids.norway_vat"),
    example: "123456789MVA",
    description: t("tax_ids.norway_vat_description"),
    enum: "no_vat",
  },
  {
    countryCode: "IS",
    country: t("tax_ids.iceland"),
    label: t("tax_ids.iceland_vat"),
    example: "123456",
    description: t("tax_ids.iceland_vat_description"),
    enum: "is_vat",
  },
  {
    countryCode: "HU",
    country: t("tax_ids.hungary"),
    label: t("tax_ids.hungary_tin"),
    example: "12345678-1-23",
    description: t("tax_ids.hungary_tin_description"),
    enum: "hu_tin",
  },
  {
    countryCode: "EU",
    country: t("tax_ids.european_union"),
    label: t("tax_ids.european_union_oss_vat"),
    example: "EU123456789",
    description: t("tax_ids.european_union_oss_vat_description"),
    enum: "eu_oss_vat",
  },
  {
    countryCode: "BG",
    country: t("tax_ids.bulgaria"),
    label: t("tax_ids.bulgaria_uic"),
    example: "123456789",
    description: t("tax_ids.bulgaria_uic_description"),
    enum: "bg_uic",
  },
];
