import Bugsnag from "@bugsnag/js";
import {
  BUGSNAG_API_KEY,
  BUGSNAG_ENABLED_RELEASE_STAGES,
} from "@circle-react/config/Settings";

// https://docs.bugsnag.com/platforms/javascript/configuration-options/#autodetecterrors
export const initializeBugsnag = () => {
  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    releaseStage: window.RAILS_ENV,
    enabledReleaseStages: BUGSNAG_ENABLED_RELEASE_STAGES,
    autoDetectErrors: false, // Disable auto detection, hence prevent bugsnag getting spammed.
  });
};

export const notifyBugsnag = error => {
  Bugsnag.notify(error);
};
