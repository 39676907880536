import { isString } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import { reactQueryPost } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { PROFILE_INFO_QUERY_KEY } from "@circle-react/hooks/profile/useProfileInfo";
import { useToast } from "@circle-react-uikit/ToastV2";
import { ARCHIVED_PROFILE_FIELDS_LIST_KEY } from "./useArchivedFields";
import { PAGE_PROFILE_FIELD_KEY } from "./usePageProfileFields";
import { PROFILE_FIELD_LIST, processBackendResponse } from "./useProfileFields";

interface ProfileField {
  id: string | number;
  [key: string]: any;
}

interface MutationParams {
  id: string | number;
}

interface ErrorResponse {
  errorDetails?: string | Record<string, unknown>;
  message?: string;
}

interface MutationContext {
  previousFields: ProfileField[];
}

export const useArchivedFieldsMutation = () => {
  const queryClient = useQueryClient();
  const toastr = useToast();

  const handleMutationSuccess = async (): Promise<void> => {
    await Promise.all([
      queryClient.invalidateQueries({
        queryKey: ARCHIVED_PROFILE_FIELDS_LIST_KEY,
      }),
      queryClient.invalidateQueries({ queryKey: PAGE_PROFILE_FIELD_KEY }),
      queryClient.invalidateQueries({ queryKey: PROFILE_INFO_QUERY_KEY }),
      queryClient.invalidateQueries({ queryKey: PROFILE_FIELD_LIST }),
    ]);
  };

  const handleToastError = (err: ErrorResponse): void => {
    const { errorDetails, message } = err;
    if (isString(errorDetails)) {
      toastr.error(errorDetails);
      return;
    }
    toastr.error(message ?? t("something_went_wrong"));
  };

  const getFilteredFields = (
    fields: ProfileField[],
    id: string | number,
  ): ProfileField[] => {
    const filteredList = fields.filter(({ id: fieldId }) => fieldId !== id);
    return processBackendResponse(filteredList);
  };

  const archiveFieldMutations = useMutation<
    void,
    ErrorResponse,
    MutationParams,
    MutationContext
  >(({ id }) => reactQueryPost(internalApi.profileFields.archive(id)), {
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries({ queryKey: PROFILE_FIELD_LIST });

      const previousFields =
        queryClient.getQueryData<ProfileField[]>(PROFILE_FIELD_LIST) ?? [];
      queryClient.setQueryData<ProfileField[]>(PROFILE_FIELD_LIST, oldFields =>
        getFilteredFields(oldFields ?? [], id),
      );
      return { previousFields };
    },
    onError: (err, _, context) => {
      handleToastError(err);
      if (context) {
        queryClient.setQueryData(
          PROFILE_FIELD_LIST,
          processBackendResponse(context.previousFields),
        );
      }
    },
    onSuccess: handleMutationSuccess,
  });

  const unarchiveFieldMutations = useMutation<
    void,
    ErrorResponse,
    MutationParams,
    MutationContext
  >(({ id }) => reactQueryPost(internalApi.profileFields.unarchive(id)), {
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries({
        queryKey: ARCHIVED_PROFILE_FIELDS_LIST_KEY,
      });

      const previousFields =
        queryClient.getQueryData<ProfileField[]>(
          ARCHIVED_PROFILE_FIELDS_LIST_KEY,
        ) ?? [];
      queryClient.setQueryData<ProfileField[]>(
        ARCHIVED_PROFILE_FIELDS_LIST_KEY,
        oldFields => getFilteredFields(oldFields ?? [], id),
      );
      return { previousFields };
    },
    onError: (err, _, context) => {
      handleToastError(err);
      if (context) {
        queryClient.setQueryData(
          ARCHIVED_PROFILE_FIELDS_LIST_KEY,
          processBackendResponse(context.previousFields),
        );
      }
    },
    onSuccess: handleMutationSuccess,
  });

  return {
    archiveFieldMutations,
    unarchiveFieldMutations,
  };
};
