import { useQuery } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { processBackendResponse } from "./useProfileFields";

export const ARCHIVED_PROFILE_FIELDS_LIST_KEY = "archived-profile-fields";

export const useArchivedFields = () => {
  const getArchivedQueryOptions = useQuery(
    ARCHIVED_PROFILE_FIELDS_LIST_KEY,
    async () => reactQueryGet(internalApi.profileFields.archivedList()),
    {
      select: data => processBackendResponse(data),
    },
  );

  return {
    getArchivedQueryOptions,
  };
};
