import { useHistory } from "react-router-dom";
import { usePunditUserContext } from "@circle-react/contexts";
import { useSmOrMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";

export const useLinkToRoot = () => {
  const { currentCommunity } = usePunditUserContext();
  const isMobile = useSmOrMdScreenMediaQuery();
  const history = useHistory();

  const goToCommunityRootPath = (post_login_redirect = null) => {
    const isHomePageEnabled = currentCommunity?.home_page_enabled;
    const postLoginRedirectQueryParam = post_login_redirect
      ? `?post_login_redirect=${post_login_redirect}`
      : "";

    if (isHomePageEnabled) {
      return history.push(`/home${postLoginRedirectQueryParam}`);
    }
    // necessary because Rails layout won't refresh when router does
    window.location.href = `/${postLoginRedirectQueryParam}`;
  };

  const goToMessagesRootPath = () => {
    if (isMobile) {
      return history.push("/messages");
    }
    goToCommunityRootPath();
  };

  return { goToCommunityRootPath, goToMessagesRootPath };
};
