import { immer } from "zustand/middleware/immer";

export interface UnreadMessagesSliceProps {
  unreadChatRoomUuids: {
    [key: number]: string[];
  };
  setUnreadChatRoomUuids: (communityId: number, uuids: string[]) => void;
  appendUnreadChatRoomUuids: (communityId: number, uuid: string) => void;
}

export const createUnreadMessagesSlice = immer<UnreadMessagesSliceProps>(
  set => ({
    unreadChatRoomUuids: {},
    setUnreadChatRoomUuids: (communityId, uuids) =>
      set(state => {
        state.unreadChatRoomUuids[communityId] = uuids;
      }),
    appendUnreadChatRoomUuids: (communityId, uuid) =>
      set(state => {
        if (!state.unreadChatRoomUuids[communityId]) {
          state.unreadChatRoomUuids[communityId] = [];
        }
        state.unreadChatRoomUuids[communityId].push(uuid);
      }),
  }),
);
