import type { AudienceType } from "@circle-react/types/AudienceList";

interface PersonHasType {
  type: AudienceType;
}

export const getIsCommunityMember = ({ type }: PersonHasType) => {
  /* If some API does not return type, let's assume it's CommunityMember */
  if (!type) return true;

  return type === "CommunityMember";
};

export const getIsCommunityLead = ({ type }: PersonHasType) =>
  type === "CommunityLead";
