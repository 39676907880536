import { t } from "@/i18n-js/instance";
import { ImageInput } from "@circle-react-uikit/ImageInput";
import { CustomUpload } from "./CustomUpload";

export const ImageUploadField = ({ title }) => (
  <ImageInput
    name="avatar"
    aspectRatio={1} // 1:1
    hideUnsplash
    hideEmbedUrl
    title={title}
    customAspectRatioHelpText={t("new_profile_photo_description")}
    renderer={props => {
      const {
        value,
        processing,
        imageUrl,
        handleChangeCoverClick,
        handleDelete,
      } = props;

      return (
        <CustomUpload
          url={value && imageUrl}
          isLoading={processing}
          onClickChange={handleChangeCoverClick}
          onClickDelete={handleDelete}
        />
      );
    }}
  />
);
