import { useQuery } from "react-query";
import invariant from "tiny-invariant";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { DISPLAY_OPTION_FIELD_INPUTS } from "@circle-react-shared/ProfileFields/constants";
import {
  DEFAULT_FIELD_INPUTS,
  FIELD_TYPES,
  FIELD_TYPE_SPECIFIC_INPUTS,
  PAGES_ATTRIBUTES,
} from "../constants";

interface ProfileFieldPage {
  name: string;
  visible: boolean;
}

interface ProfileFieldResponse {
  choices?: any[];
  number_options?: any;
  pages?: ProfileFieldPage[];
  key?: string;
  id: number;
  label: string;
  [key: string]: any;
}

export const getProfileFieldDetailQueryKey = (
  fieldId?: number | string,
): [string, number | string] | [string] => {
  const key = "PROFILE_FIELD_DETAIL";
  if (fieldId) {
    return [key, fieldId];
  }
  return [key];
};

const getProfileFieldPages = (
  pages: ProfileFieldPage[] = [],
  key?: string,
): ProfileFieldPage[] => {
  const pageNames =
    key === "headline"
      ? Object.values(DISPLAY_OPTION_FIELD_INPUTS)
      : Object.values(DISPLAY_OPTION_FIELD_INPUTS).filter(
          field => field !== DISPLAY_OPTION_FIELD_INPUTS.COMMUNITY_VIEW,
        );

  return pageNames.map(pageName => {
    const page = pages.find(page => page.name === pageName);
    if (page) {
      return {
        ...page,
        visible: page.visible,
      };
    }
    return {
      name: pageName,
      visible: true,
    };
  });
};

export const processFieldType = (response: ProfileFieldResponse) => ({
  ...response,
  /* Backend has different names for these on GET vs UPDATE APIs */
  [FIELD_TYPE_SPECIFIC_INPUTS[FIELD_TYPES.SELECT].CHOICES_ATTRIBUTES]:
    response.choices,
  [FIELD_TYPE_SPECIFIC_INPUTS[FIELD_TYPES.NUMBER].NUMBER_OPTIONS]:
    response.number_options,
  [PAGES_ATTRIBUTES]: getProfileFieldPages(response.pages, response.key),
  [DEFAULT_FIELD_INPUTS.HIDDEN]: response.pages?.every(
    ({ visible }) => !visible,
  ),
});

interface UseProfileFieldDetailProps {
  fieldId?: number | string;
}

export const useProfileFieldDetail = ({
  fieldId,
}: UseProfileFieldDetailProps) => {
  const getOptions = useQuery(
    getProfileFieldDetailQueryKey(fieldId),
    async () => {
      invariant(fieldId, "Field ID is required");
      return reactQueryGet(internalApi.profileFields.detail(fieldId));
    },
    {
      select: data => processFieldType(data),
      enabled: !!fieldId,
    },
  );

  return {
    getOptions,
  };
};
